<template>
  <main class="ControlTowerOccurrences">
    <!-- Components -->
    <router-view />
  </main>
</template>
<script>
//Views
export default {
  name: 'ControlTowerOccurrences',
}
</script>
<style scoped lang="scss">
.ControlTowerOccurrences {
  @media #{$mobile-view} {
    /deep/ .BaseTabs {
      overflow-x: scroll;
      width: 100vw;
    }
  }
}
</style>
